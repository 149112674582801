import { TPropsAttractionsPage } from '@/entities/Attractions/domain/Attractions.domain';
import { getFeaturedSectionTitle } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/utils';
import { GetStaticProps } from 'next';
import { redirectToMS } from 'utils/redirect';
import { getActivityDateless } from '../../../src/entities/Attractions/service/ApiAttractionsPage';
import {
    CreateAttractionsPageTitle,
    createAttractionsPageMeta,
    createFaqSlug,
    getCategory,
    getDestination,
} from '../../../src/entities/Attractions/service/Creators';
import { AttractionsPage } from '../../../src/screens/AttractionsPage/ui/AttractionsPage';
import { getFAQ } from '../../../src/shared/FAQ/service/ApiFaq';
import { getRecommends } from '@/shared/FeaturedActivities/service/ApiFeaturedActivities';
import { getCategoryDescription } from '@/shared/TopAttractions/service/ApiTopAttractions';
import { attributes, parseAttr } from '@/common/app/constants/attributions';
import {
    getCardImagesWithPlaceholders,
    getPlaceholders,
} from '@/common/service/api/Activity/Activity';

export async function getStaticPaths() {
    return {
        paths: [],
        fallback: 'blocking',
    };
}

type PageStaticProps = {
    destination: string;
    category: string;
};

export const getStaticProps: GetStaticProps<TPropsAttractionsPage, PageStaticProps> = async ({
    params,
}) => {
    try {
        if (!params) {
            throw new Error(`PAGE ATTRACTIONS /DESTINATION/CATEGORY: BAD PARAMS: ${params}`);
        }

        const destinationSlug = params.destination;
        const categorySlug = params.category;
        const currentDestination = getDestination(destinationSlug);
        const currentCategory = getCategory(categorySlug);

        if (!currentDestination || !currentCategory) {
            return {
                revalidate: Number(process.env.CACHE_ENV),
                ...redirectToMS(`attractions/${Object.values(params).join('/')}`),
            };
        }

        if (currentDestination.slug !== destinationSlug || currentCategory.slug !== categorySlug) {
            return {
                redirect: {
                    destination: `/attractions/${currentDestination.slug}/${currentCategory.slug}/`,
                    permanent: true,
                },
            };
        }

        const title = CreateAttractionsPageTitle(currentDestination, currentCategory);
        const featuredActivitiesTitle = getFeaturedSectionTitle(
            currentDestination.name,
            currentCategory.name
        );

        const faqSlug = createFaqSlug(currentDestination, currentCategory);

        const breadcrumbs = [
            {
                url: currentDestination.url,
                title: currentDestination.name,
            },
            {
                url: `${currentDestination.url_search}`,
                title: 'Things to do',
            },
            {
                url: `/attractions/${currentDestination.slug}/${currentCategory.slug}`,
                title: currentCategory.name,
                unlink: true,
            },
        ];

        const [fullList, faq, recommends, categoryDescription] = await Promise.all([
            getActivityDateless(currentDestination.id, currentCategory.id),
            getFAQ(faqSlug),
            getRecommends({
                category_id: currentCategory.id,
                destination_id: currentDestination.id,
            }),
            getCategoryDescription(`${currentDestination?.slug}_${currentCategory?.slug}`),
        ]);

        if (
            !fullList.total &&
            !currentDestination.categories?.find((i) => i.id === currentCategory.id)
                ?.totalActivities
        ) {
            return {
                redirect: {
                    destination: `/404/`,
                    permanent: true,
                },
            };
        }

        const meta = createAttractionsPageMeta(currentDestination, currentCategory, fullList.total);

        const availableAttributes: number[] = [];
        const ids = fullList.items.map(({ id }) => id);

        const images = await getCardImagesWithPlaceholders(ids);
        const placeholders = await getPlaceholders(ids, 'activity');

        fullList.items.forEach((trip) => {
            trip.price = 0;
            trip.images = images[trip.id] || [];
            trip.placeholder = placeholders[trip.id] || '';
            if (trip.attributes) {
                const attrs = parseAttr(trip.attributes);
                availableAttributes.push(...attrs.filter((i) => !availableAttributes.includes(i)));
            }
        });

        return {
            revalidate: Number(process.env.CACHE_ENV),
            props: {
                meta,
                breadcrumbs,
                title,
                featuredActivitiesTitle,
                fullList,
                faq,
                currentDestination,
                currentCategory,
                isEmpty: !fullList.total,
                noRecommends: !recommends.length,
                categoryDescription,
                availableAttributes: attributes.filter((i) => availableAttributes.includes(i.id)),
                placeholders,
            },
        };
    } catch (e) {
        throw new Error((e as Error).message);
    }
};

export default AttractionsPage;
