
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/attractions/[destination]/[category]",
      function () {
        return require("private-next-pages/attractions/[destination]/[category].ts");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/attractions/[destination]/[category]"])
      });
    }
  